import React from 'react';


function FooterDesktop() {
  return (
    <div className="footer-desktop">
      SIDEWALKS Comic All rights reserved 2024
    </div>
  );
}

export default FooterDesktop;
