import React from "react";
import { useMediaQuery } from "@mui/material";

import HeroSectionDesktop from "../components/Desktop/HeroSectionDesktop";

import Footer from '../components/Desktop/FooterDesktop'

import HeroSectionMobile from '../components/Mobile/HeroSectionMobile'

import FooterMobile from "../components/Mobile/FooterMobile";


import VideoBackground from "./VideoBackground.jsx";


function YourComponent() {
  // Define your custom breakpoints

  const desktopBreakpoint = useMediaQuery("(min-width: 1450px)");
  const tabletBreakpoint = useMediaQuery("(max-width: 1449px) and (min-width: 1100px)");
  const mobileBreakpoint = useMediaQuery("(max-width: 1099px)");

  return (
    <div>
      {desktopBreakpoint && (
        <div>
          {/* Content for desktop view */}
          <VideoBackground/>
          <HeroSectionDesktop/>

   <Footer/>
        </div>
      )}
      {tabletBreakpoint && (
        <div>
          {/* Content for tablet view */}
      
          <VideoBackground/>
          <HeroSectionDesktop/>

   <Footer/>
        </div>
      )}
      {mobileBreakpoint && (
        <div>
          {/* Content for mobile view */}
         
          <HeroSectionMobile/>
      
<FooterMobile/>
        </div>
      )}
      
    </div>
  );
}

export default YourComponent;
