import React, { useState, useEffect } from "react";
import images from "./logo/skull.png";
import "./Navbars.css";
import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import { FaChevronDown } from "react-icons/fa"; // Importing a chevron down icon
import { FaTiktok } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [showLaunchButton, setShowLaunchButton] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
      setShowLaunchButton(window.scrollY > 700);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const dropdownVariants = {
    hidden: {
      opacity: 0,
      y: -20,
      transition: { duration: 0.3 }
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.3 }
    }
  };

  return (
    <nav className={`app__navbar${isSticky ? " sticky" : ""}`}>
      <Grid container>
        <Grid item xs={6} md={3}>
          <div className="app__navbar-logo">
            <img src={images} alt="app__logo" /> <h1 className="LOGO_TEXT">SIDEWALKS</h1>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <ul className="app__navbar-links">
            {/* Add additional navbar links here */}
          </ul>
        </Grid>
        <Grid item xs={6} md={3}>
          <ul className="app__navbar-links" style={{ position: "relative", right: "60px" }}>
            <li
              className="p__opensans"
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
              style={{ position: "relative", display: "flex", alignItems: "center" }}
            >
              <a
               
              
                style={{ color: "#000", fontWeight: "400", textDecoration: "none", fontSize: "25px", display: "flex", alignItems: "center" }}
              >
                Socials
                <motion.div
                  animate={{ rotate: showDropdown ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                  style={{  marginLeft: "6px", top:"7px" , position:"relative" }}
                >
                  <FaChevronDown  style={{ width:"13px"}} />
                </motion.div>
              </a>
              {showDropdown && (
                <motion.div
                  className="dropdown-menu"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={dropdownVariants}
                >
                  <a href="https://www.tiktok.com/@sidewalk.comic?_t=8nmSmTYM29C&_r=1" target="_blank" >
                  <FaTiktok  style={{ color: "#000", fontWeight: "400", textDecoration: "none", fontSize: "20px" , position:"relative" , top:"5px" }}/>
                  &nbsp; Tiktok
                  </a>
                  <a href="https://www.instagram.com/sidewalks.comic?igsh=MXAyZng4Y3ZuZm9mZA==" target="_blank" > <FaInstagram   style={{ color: "#000", fontWeight: "400", textDecoration: "none", fontSize: "20px" , position:"relative" , top:"5px" }}/> Instagram</a>
                </motion.div>
              )}
            </li>
            <li className="p__opensans">
              <a
                href=""
                target="_blank"
                style={{ color: "#000", fontWeight: "400", textDecoration: "none", fontSize: "25px" }}
              >
                About us
              </a>
            </li>
          </ul>
        </Grid>
      </Grid>

    
    </nav>
  );
};

export default Navbar;
