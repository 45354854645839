import React, { useState } from 'react';
import Navbar from '../../Navbar/Navbar';
import { Grid } from '@mui/material';

import kickstartbtn_disabled from './image/kickstarter_btn.png';
import kickstartbtn_pressed from './image/kickstarter_btn_pressed.png';


import patreonbtn_disabled from './image/Patreon_btn_idle.png';
import patreonbtn_pressed from './image/Patreon_btn_pressed.png';

import discordbtn_disabled from './image/discord_btn_desabled.png';
import discordbtn_pressed from './image/discord_btn_pressed.png';


import jabba_pee from './image/jabba_peeing.gif'

function HeroSectionDesktop() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  const [isHoveredp, setIsHoveredp] = useState(false);

  const handleMouseEnterp = () => {
    setIsHoveredp(true);
  };

  const handleMouseLeavep = () => {
    setIsHoveredp(false);
  };

  const [isHoveredd, setIsHoveredd] = useState(false);

  const handleMouseEnterd = () => {
    setIsHoveredd(true);
  };

  const handleMouseLeaved = () => {
    setIsHoveredd(false);
  };


  return (
    <div className=''>
      <Navbar />
     
      <div className="fixed-bottom-container">

     

        <Grid container justifyContent="center">

       
  
        <div className='container'>
        <Grid container>
          <Grid item md={3} lg={3} xs={3} sm={3}></Grid>
          <Grid item md={6} lg={6} xs={6} sm={6}>
            <div className='centeritall'>
         
            <a href='' onMouseEnter={handleMouseEnterp} onMouseLeave={handleMouseLeavep}>
                <img src={isHoveredp ?  patreonbtn_pressed  : patreonbtn_disabled} style={{ width: "60px" }} />
              </a>

              &nbsp; &nbsp;


            <a href='https://www.kickstarter.com/profile/sidewalkscomic' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <img src={isHovered ? kickstartbtn_pressed : kickstartbtn_disabled} style={{ width: "250px" }} />
              </a>      
              
              &nbsp; &nbsp;
           

              <a href='' onMouseEnter={handleMouseEnterd} onMouseLeave={handleMouseLeaved}>
                <img src={isHoveredd ?  discordbtn_pressed  : discordbtn_disabled} style={{ width: "60px" }} />
              </a>


            </div>
          </Grid>
          <Grid item md={3} lg={3} xs={3} sm={3}></Grid>
        </Grid>
      </div>
        </Grid>
      </div>
    </div>
  );
}

export default HeroSectionDesktop;
