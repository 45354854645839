// src/components/VideoBackground.js

import React from 'react';
import sidewalksVideo from './sidewalks.mp4'; // Update the path to your video file

const VideoBackground = () => {
  return (
    <div className="video-background">
      <video autoPlay loop muted playsInline className="video">
        <source src={sidewalksVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoBackground;
