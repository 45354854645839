import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTiktok, FaInstagram } from 'react-icons/fa';
import { gsap } from 'gsap';
import logo from './logo/logom.png';
import logos from './logo/scalp.png';
import './Navbars.css';

const MobileNavbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const imgRef = useRef(null);

  const dropdownVariants = {
    hidden: {
      opacity: 0,
      y: -20,
      transition: { duration: 0.3 },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.3 },
    },
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    gsap.to(imgRef.current, {
      opacity: 0,
      duration: 0.5,
      onComplete: () => {
        setShowDropdown((prevState) => !prevState);
        gsap.to(imgRef.current, { opacity: 1, duration: 0.5 });
      },
    });
  };

  return (
    <div className='container'>
      <br />
      <Grid container>
        <Grid item md={6} xs={6} sm={6}>
          <h1 className="LOGO_TEXTM">SIDEWALKS</h1>
        </Grid>
        <Grid item md={6} xs={6} sm={6} className='enditall'>
          <div style={{ position: 'relative' }} ref={dropdownRef}>
            <div onClick={handleClick}>
              <img
                ref={imgRef}
                src={logos}
                style={{width:"50px"}}
                alt="Menu Icon"
                className="menu-icon"
              />
            </div>
            <AnimatePresence>
              {showDropdown && (
                <motion.div
                  className="dropdown-menum"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={dropdownVariants}
                  style={{ position: 'absolute', top: '60px', right: 0 }}
                >
                  <a
                    href="https://www.tiktok.com/@sidewalk.comic?_t=8nmSmTYM29C&_r=1"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dropdown-link"
                  >
                    <FaTiktok className="dropdown-icon" />
                    &nbsp; Tiktok
                  </a>
                  <a
                    href="https://www.instagram.com/sidewalks.comic?igsh=MXAyZng4Y3ZuZm9mZA=="
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dropdown-link"
                  >
                    <FaInstagram className="dropdown-icon" />
                    &nbsp; Instagram
                  </a>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MobileNavbar;
