import React, { useState } from 'react';
import Navbar from '../../Navbar/MobileNavbar';
import { Grid } from '@mui/material';

import kickstartbtn_disabled from './image/kickstarter_btn_idlem.png';
import kickstartbtn_pressed from './image/kickstarter_btn_hoverm.png';


import patreonbtn_disabled from './image/mobile_Patreon_btn_idlem.png';
import patreonbtn_pressed from './image/mobile_Patreon_btn_pressedm.png';

import discordbtn_disabled from './image/mobile_discord_btn_idlem.png';
import discordbtn_pressed from './image/mobile_discord_btn_pressedm.png';


import jabba_pee from './image/jabba_peeing.gif'

function HeroSectionDesktop() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  const [isHoveredp, setIsHoveredp] = useState(false);

  const handleMouseEnterp = () => {
    setIsHoveredp(true);
  };

  const handleMouseLeavep = () => {
    setIsHoveredp(false);
  };

  const [isHoveredd, setIsHoveredd] = useState(false);

  const handleMouseEnterd = () => {
    setIsHoveredd(true);
  };

  const handleMouseLeaved = () => {
    setIsHoveredd(false);
  };


  return (
    <div className='hero_background_mobile'>
      <Navbar />
     
      <div className="fixed-bottom-container">

      

        <Grid container justifyContent="center">

       
  
        <div className='container'>
        <Grid container>
          <Grid item md={12} lg={12} xs={12} sm={12}>

          <div className='centeritall'>
         
     


         <a href='https://www.kickstarter.com/profile/sidewalkscomic' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
             <img src={isHovered ? kickstartbtn_pressed : kickstartbtn_disabled} style={{ width: "250px" }} />
           </a>      
        


         </div>

          </Grid>
          <Grid item md={12} lg={12} xs={12} sm={12}>
            <br/>
            <div className='centeritall'>
        
              <a href='' onMouseEnter={handleMouseEnterp} onMouseLeave={handleMouseLeavep}>
                <img src={isHoveredp ?  patreonbtn_pressed  : patreonbtn_disabled} style={{ width: "100px" }} />
              </a>
              &nbsp; &nbsp;

              <a href='' onMouseEnter={handleMouseEnterd} onMouseLeave={handleMouseLeaved}>
                <img src={isHoveredd ?  discordbtn_pressed  : discordbtn_disabled} style={{ width: "100px" }} />
              </a>
            </div>
          </Grid>
         
        </Grid>
      </div>
        </Grid>
      </div>
    </div>
  );
}

export default HeroSectionDesktop;
